<template>
    <b-modal id="notif-permission-modal" title="Notifikasi">
        <div class="modal-body">Ijinkan aplikasi untuk mengirim notifikasi?
        </div>
        <ErrorMessage v-bind:error="error" />
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-default" @click="$bvModal.hide('notif-permission-modal')">Lain
                Kali</button>
            <button type="button" :disabled="onProgress" class="btn btn-primary tst3" @click="confirmPermission"><i
                    v-if="onProgress" class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Ya</button>
        </template>
    </b-modal>
</template>

<script>
    import ErrorMessage from '../components/ErrorMessage'

    export default {
        data: function () {
            return {
                onProgress: false,
                error: {}
            }
        },
        methods: {
            confirmPermission: async function () {
                this.error = {}
                this.onProgress = true
                try {
                    this.$bvModal.hide('notif-permission-modal');
                    var permissionResult = await Notification.requestPermission();
                    console.log(permissionResult)
                    if (permissionResult !== 'granted') {
                        throw new Error('We weren\'t granted permission.')
                    }
                    this.onProgress = false
                } catch (error) {
                    this.onProgress = false
                    this.error = this.$helpers.createError(error)
                }
            }
        },
        components: {
            ErrorMessage
        }
    }
</script>