<template>
    <div class="container box-shadow-login">
        <div class="row">
            <div class="col-md-4 login-sec logo-login">
                <img src="images/logo-login.png" />
            </div>
            <div class="col-md-8 login-form">
                <h3 class="title text-center title-login">Login to Bippotalk</h3>
                <form class="form-material m-t-20">
                    <div class="form-group">
                        <label for="companyId">Kode Perusahaan<span class="help"> </span></label>
                        <input v-on:keyup.enter="login" v-model="companyId" type="email" id="companyId"
                            name="example-email" class="form-control" placeholder="Ketik Kode Perusahaan di sini">
                    </div>
                    <div class="form-group">
                        <label for="example-email">Email <span class="help"> </span></label>
                        <input v-on:keyup.enter="login" v-model="userId" type="email" id="example-email"
                            name="example-email" class="form-control" placeholder="Ketik email di sini"> </div>
                    <div class="form-group">
                        <label>Kata Sandi</label>
                        <input v-on:keyup.enter="login" v-model="password" type="password" class="form-control"
                            value="Ketik kata sandi di sini">
                    </div>
                </form>
                <br>
                <div class="row">
                    <div class="col-md-6"><a href="/unassigned"></a></div>
                    <div class="col-md-6 text-right">
                        <button id="btn-login" type="button" class="btn btn-primary" v-on:click="login">
                            <i v-if="isLoginInProgress" class="fa fa-circle-o-notch fa-spin"></i> &nbsp;LOGIN
                        </button>
                    </div>
                </div>
                <br>
                <ErrorMessage v-bind:error="error" />
            </div>
        </div>
        <NotifPermission ref="notifPermission" />
    </div>
</template>
<script>
    var firebase = require("firebase");
    var cookie = require('cookie');
    var axios = require("axios");
    import LoginLayout from "../layouts/LoginLayout"
    import {
        mapMutations
    } from 'vuex'
    import ErrorMessage from '../components/ErrorMessage'
    import NotifPermission from '../components/NotifPermission'


    export default {
        data: function () {
            return {
                isLoginInProgress: false,
                userId: '',
                password: '',
                companyId: '',
                error: ''
            }
        },
        methods: {
            ...mapMutations({
                setSessionInfo: 'session/setSessionInfo',
                setCommerce: 'session/setCommerce'
            }),
            login: async function () {
                var loginData = {
                    userId: this.userId,
                    password: this.password,
                    companyId: this.companyId
                };
                this.isLoginInProgress = true;

                this.isLoginInProgress = true;
                try {
                    if (this.$socket.connected) this.$socket.disconnect()
                    let resp = await axios.post(process.env.VUE_APP_API_URL + '/auth/login', loginData);
                    this.$socket.connect()

                    var jsonData = resp.data;
                    this.setSessionInfo({
                        isLoggedIn: true,
                        userId: jsonData.id,
                        userFullName: jsonData.name,
                        tenantId: jsonData.tenantId,
                        defaultCountryCode: jsonData.defaultContryCode,
                        commerceIntegration: jsonData.commerceIntegration,
                        fbToken: jsonData.token,
                        activeChannels: jsonData.activeChannels,
                        ui: jsonData.ui
                    })

                    const apiUrl = jsonData.tenantId == 'gitapelangi2' ? process.env.VUE_APP_COMMERCE_GP_API_URL : process.env.VUE_APP_COMMERCE_API_URL
                    const webUrl = jsonData.tenantId == 'gitapelangi2' ? process.env.VUE_APP_COMMERCE_GP_WEB_URL : process.env.VUE_APP_COMMERCE_WEB_URL

                    this.setCommerce({
                        apiUrl: apiUrl,
                        webUrl: webUrl
                    })

                    document.cookie = cookie.serialize('fbtoken', jsonData.token);

                    if (firebase.apps.length === 0) {
                        var config = {
                            apiKey: process.env.VUE_APP_FB_API_KEY,
                            authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
                            databaseURL: process.env.VUE_APP_FB_DATABASE_URL,
                            storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET
                        };

                        firebase.initializeApp(config);
                    }

                    try {
                        await firebase.auth().signInWithCustomToken(jsonData.token)
                    } catch (error) {
                        console.error(error)
                    }
                    this.$router.push('/unassigned/');
                } catch (error) {
                    this.error = this.$helpers.createError(error)
                }
                this.isLoginInProgress = false;
            }
        },
        created() {
            console.log(this.$socket)
            this.$emit('update:layout', LoginLayout);
        },
        mounted: function () {
            if (Notification.permission !== "granted" && Notification.permission !== "denied") {
                this.$bvModal.show('notif-permission-modal');
            }
        },
        components: {
            ErrorMessage,
            NotifPermission
        }
    }
</script>