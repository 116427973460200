<template>
    <section class="login-block">
        <slot />
    </section>
</template>
<script>
export default {
    name: 'LoginLayout'
}
</script>
<style>
  @import '../assets/css/style.css';
  @import '../assets/css/colors/blue.css';
  @import '../assets/css/custom.css';
</style>